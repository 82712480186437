<template>
  <div class="conteudo-interno">
    <modal-parametro v-if="modalConfirmacao" @fecharModal="atualizaTela($event.value)" :idParametro="idParametro" />
    <div class="nova-comunicacao-container">
      <h1 class="h1-margin">Gerenciamento de parâmetros</h1>

      <div class="tabela-informacoes">
        <div class="tabela-linha tabela-linha-titulo">

          <div class="tabela-linha-id">
            <p>Id</p>
          </div>
          <div class="tabela-linha-nome">
            <p>Nome</p>
          </div>
          <div class="tabela-linha-porcentagem">
            <p>Descrição</p>
          </div>
          <div class="tabela-linha-status">
            <p>Status</p>
          </div>
          <div class="tabela-linha-id"></div>
        </div>

        <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in listaStatus" :key="index">
          <div class="tabela-linha-id">
            <p> {{ item.id }} </p>
          </div>
          <div class="tabela-linha-nome">
            <p> {{ item.nome }} </p>
          </div>
          <div class="tabela-linha-porcentagem">
            <p class="linha-inteira"> {{ item.descricao }} </p>
          </div>
          <div class="tabela-linha-status">
            <input :id="`switch-shadow-${index}`" class="switch switch--shadow" type="checkbox" :checked="item.ativo"
              @click="chamaModal(item.id)">
            <label :for="`switch-shadow-${index}`"></label>
          </div>
          <div class="tabela-linha-btns">
            <div class="tabela-linha-btns-editar" @click="$router.push(`/gerenciamento-sistema/detalhes/${item.id}`)">
              <img src="@/assets/images/icones/ver-mais.png" alt="Visualizar histórico" class="icone-branco" />
            </div>
          </div>
        </div>

        <div class="paginacao" v-if="totalPaginas">
          <!-- <div class="paginacao"> -->
          <button :disabled="pagina === 1" @click="anteriorPag">
            Anterior
          </button>
          <p>{{ pagina }} - {{ totalPaginas }}</p>
          <button :disabled="pagina === totalPaginas" @click="proximaPag">
            Próxima
          </button>
        </div>

      </div>

    </div>
  </div>
</template>
  
<script>
import moment from "moment";
import { verTodosStatus } from "@/services/parametros"
import ModalParametro from "@/components/estrutura/modal/_ModalParametro.vue";

export default {
  name: "GerenciamentoListar",

  components: {
    ModalParametro
  },

  data() {
    return {
      listaStatus: [],
      modalConfirmacao: false,
      idParametro: 0,
      pagina: 1,
      qtdPorPagina: 10,
      totalPaginas: 0
    };
  },

  methods: {
    atualizaTela(value) {
      this.modalConfirmacao = value;
      this.listarStatus();
    },
    chamaModal(value) {
      this.modalConfirmacao = true;
      this.idParametro = value;
    },
    listarStatus() {
      this.listaStatus = null;
      verTodosStatus(this.pagina, this.qtdPorPagina).then((resp) => {
        this.listaStatus = resp.data.lista;
        this.totalPaginas = resp.data.paginacao.totalPaginas;
      })
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    proximaPag() {
      this.pagina++;
      this.listarStatus();
    },
    anteriorPag() {
      this.pagina--;
      this.listarStatus();
    },
  },

  mounted() {
    this.listarStatus();
  }
};
</script>

<style lang="scss" scoped>
.linha-inteira {
  width: 100% !important;
}
.h1-margin {
  margin-bottom: 100px !important;
}

.icone-branco {
  filter: invert(1);
}

/* Botão switch*/

.switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
}

.switch+label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow+label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 60px;
}

.switch--shadow+label:before,
.switch--shadow+label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

.switch--shadow+label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: background 0.4s;
}

.switch--shadow+label:after {
  width: 32px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch--shadow:checked+label:before {
  background-color: #8ce196;
}

.switch--shadow:checked+label:after {
  transform: translateX(30px);
}
</style>  